import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SearchPage.css';

function formatDate(isoDate) {
  const date = new Date(isoDate);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

function SearchPage() {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedResult, setSelectedResult] = useState('');
  const [results, setResults] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showSuggestionsList, setSuggestionsList] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedYear, setSelectedYear] = useState(2023);

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const years = [];
  years.push('All Years');
  for (let year = 2024; year >= 2010; year--) {
    years.push(year);
  }

  const fetchResults = (searchText) => {
    axios
      .get(`/api/lca/find?name=${searchText}&year=${selectedYear}`)
      .then((response) => {
        if (response.status === 200) {
          setResults(response.data);
        } else {
          setResults([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

  const handleNameClick = (name) => {
    setQuery(name); 
    setSuggestionsList(false);
    setSuggestions([]); 
    fetchResults(name); 
    setShowTable(true);
  };

  const handleSearch = () => {
    if (query) {
      fetchResults(query);
      setShowTable(true);
      setSuggestionsList(false);
    }
  };
  
  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setQuery(inputText)
    if (inputText) {
      setSuggestionsList(true);
      axios
        .get(`/api/lca/suggestions/${inputText}`)
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const sortedResults = [...results];

  if (sortField) {
    sortedResults.sort((a, b) => {
      const valueA = a[sortField];
      const valueB = b[sortField];
  
      if (sortField === 'BEGIN_DATE' || sortField === 'END_DATE') {
        // Parse the date strings into Date objects
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
  
        return (dateA - dateB) * (sortOrder === 'asc' ? 1 : -1);
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        const sortOrderMultiplier = sortOrder === 'asc' ? 1 : -1;
        return valueA.localeCompare(valueB) * sortOrderMultiplier;
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return (valueA - valueB) * (sortOrder === 'asc' ? 1 : -1);
      } else {
        return a._id.localeCompare(b._id);
      }
    });
  }
  

  return (
    <div>
      <center>
        <div className="search-head"> Search US Salaries </div>
        <div> Top Companies: 
          <a onClick={(e) => { e.preventDefault(); handleNameClick("Microsoft Corporation"); }} >Microsoft</a>
          <a onClick={(e) => { e.preventDefault();handleNameClick("Facebook"); }} >, Facebook</a>
          <a onClick={(e) => { e.preventDefault();handleNameClick("Amazon"); }} >, Amazon</a>
          <a onClick={(e) => { e.preventDefault();handleNameClick("Tesla"); }} >, Tesla</a>
          <a onClick={(e) => { e.preventDefault();handleNameClick("Airbnb"); }} >, Airbnb</a>
          <a onClick={(e) => { e.preventDefault();handleNameClick("Apple"); }} >, Apple</a>
          <a onClick={(e) => { e.preventDefault();handleNameClick("Netflix"); }} >, Netflix</a>
          <a onClick={(e) => { e.preventDefault();handleNameClick("Uber"); }} >, Uber</a>
        </div>

     

        <div className="search-container">
          <input
            type="text"
            placeholder="Search Company"
            value={query}
            onChange={handleInputChange}
          />
  
          <select value={selectedYear} onChange={handleYearChange}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        
          <button onClick={handleSearch}>Search</button>
        </div>
        


        {showSuggestionsList && suggestions.length > 0 &&(
          <div className="suggestionResult">
              <ul>
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion}
                    onClick={() => handleNameClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
          </div>
        )}
      </center>
      <div className="before_result"></div>
      <center>
        {showTable && (
          <div>
            {results.length > 0 ?(
            <table className="tab_result">
              <thead>
                <tr>
                  <th className="col-e" onClick={() => handleSort('EMPLOYER_NAME')}> Employer <span>{sortField === 'EMPLOYER_NAME' ? (sortOrder === 'asc' ? '🔼' : '🔽'  ) : '🔼'}</span> </th>
                  <th className="col-j" onClick={() => handleSort('JOB_TITLE')}>Job Title <span>{sortField === 'JOB_TITLE' ? (sortOrder === 'asc' ? '🔼' : '🔽'  ) : '🔼'}</span> </th>
                  <th className="col-w" onClick={() => handleSort('PREVAILING_WAGE')}>Salary <span>{sortField === 'PREVAILING_WAGE' ? (sortOrder === 'asc' ? '🔼' : '🔽'  ) : '🔼'}</span> </th>
                  <th className="col-c" onClick={() => handleSort('EMPLOYER_CITY')}>City <span>{sortField === 'EMPLOYER_CITY' ? (sortOrder === 'asc' ? '🔼' : '🔽'  ) : '🔼'}</span> </th>
                  <th className="col-s" onClick={() => handleSort('EMPLOYER_STATE')}>State <span>{sortField === 'EMPLOYER_STATE' ? (sortOrder === 'asc' ? '🔼' : '🔽'  ) : '🔼'}</span> </th>
                  <th className="col-d" onClick={() => handleSort('BEGIN_DATE')}>Start Date <span>{sortField === 'BEGIN_DATE' ? (sortOrder === 'asc' ? '🔼' : '🔽'  ) : '🔼'}</span> </th>
                  <th className="col-d" onClick={() => handleSort('END_DATE')}>End Date <span>{sortField === 'END_DATE' ? (sortOrder === 'asc' ? '🔼' : '🔽'  ) : '🔼'}</span> </th>
                </tr>
              </thead>
              <tbody>
                {sortedResults.map((result) => (
                  <tr key={result._id}>
                    <td>{result.EMPLOYER_NAME}</td>
                    <td>{result.JOB_TITLE}</td>
                    <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(result.PREVAILING_WAGE)}</td>
                    <td>{result.EMPLOYER_CITY}</td>
                    <td>{result.EMPLOYER_STATE}</td>
                    <td>{formatDate(result.BEGIN_DATE)}</td>
                    <td>{formatDate(result.END_DATE)}</td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
            ):(
              true
             /* <h3>No data found</h3> */
            )}
          </div>
        )}
      </center>
      
    </div>
  );
}

export default SearchPage;
