import React from "react";
import { Link } from "react-router-dom";
import './App.css';
import logo from './assets/img/logo.png';

const Nav = ({ setShowTable }) => {
  const handleLogoClick = () => {
    setShowTable(false);
  };

  return (
    <div>
      <ul className="nav">
        <li>
          <Link to="/" onClick={handleLogoClick}>
            <img src={logo} alt="h1b pay" style={{ width: '250px' }} />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
