import './App.css';
import './pages/SearchPage.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SearchPage from './pages/SearchPage.js';
import PageNotFound from './pages/PageNotFound.js'
import Nav from './nav.js';
import Footer from './pages/Footer.js';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Nav />
        <Routes>
          <Route path="/" element={<SearchPage />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </div>
     
    </BrowserRouter>

  );
}

export default App;
