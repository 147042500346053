import React from 'react';
import './Footer.css';

function Footer() {
  return (
   
      <div className="container">
        <p>&copy; {new Date().getFullYear()} H1BPay.info All Rights Reserved | Support: info@h1bpay.info</p>
        <p>This website compiles and indexes the disclosure data of Labor Condition Applications (LCAs) sourced from the United States Department of Labor (DOL).</p>
      </div>
  );
}

export default Footer;
