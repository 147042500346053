import React from 'react';
import './Footer.css';

function PageNotFound() {
  return (
    <div className="PageNotFound">
      <h1>Page Not Available</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}

export default PageNotFound;
